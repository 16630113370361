<template>
  <div>
    <v-container>
      <h2>
        Are you giving yourself the best opportunity to be a SUCCESSFUL ATHLETE
        ?
      </h2>

      <p>
        I want every athlete I train to be successful but sometimes I wonder if
        they want to be successful? Do they know what it takes to be successful?
        Do they want to be successful as much as I want them to be successful?
        Success isn’t something you can wish for if you want it you must go get
        it. Here are some pieces of the puzzle that I discovered that gave me a
        shot at success.
      </p>

      <h3>Set Goals</h3>

      <p>
        Be ambitious! If you don’t have people telling you that you can’t
        achieve your goal(s) then you aren’t challenging yourself enough. I
        encourage athletes to write down their goals some place where they can
        see it every day. This physical representation will help fuel your
        drive.
      </p>

      <h3>Create a Plan</h3>
      <p>
        Your plan can be short term or long term, it doesn't matter. Just have a
        plan. Creating a plan gives yourself a direction for growth/development
        as a player. Try reverse engineering the process. Visualize the end
        result and work backwards.
      </p>

      <h3>Have Fun</h3>

      <p>
        Always enjoy the sport you are playing! Never have a bad day at the
        office!!! Keep the game fun and don’t dwell on failure. Physically we
        will make mistakes and have games that don’t always reflect our
        potential. Bad games are inescapable but stay optimistic. Don’t let
        failure or the fear of failure take the joy of competition away for you!
        Remember you started playing this game because it was fun, keep it that
        way! Successful athletes enjoy what they do.
      </p>

      <h3>Environment</h3>

      <p>
        We have all heard the question: what is more important Nature or
        Nurture? Well since we can’t handpick our parents (Nature) we can
        somewhat control our Nurture! The environment you are brought up in and
        shaped by is just as important as your genetic make-up. If you want to
        be a successful athlete then surround yourself with those who also want
        to be a successful athlete! Start off with your social group; associate
        yourself with those who want you to excel. Constantly surrounded by this
        positive atmosphere will give you more confidence and bring out the most
        in you. Give value to those who push you every day and encourage growth.
        Success breeds success.
      </p>

      <h3>Role Model</h3>

      <p>
        PSA to ALL athletes, believe it or not young kids idolize you. Kids come
        to your games, kids hear what you say, and kids notice your character.
        So set a good example for the youth! Strive to be a class act everywhere
        you go. Go out of your way to interact with a kid if the opportunity
        occurs, a simple interaction like this will make their day and they will
        probably become your #1 fan. I encourage you to be someone’s role model.
        Make it a goal to positively influence others with your actions.
        Remember good things happen to good people.
      </p>

      <h3>Priorities</h3>

      <p>
        It’s not what you are willing to do, it's what you are willing to
        sacrifice. If you want to be a successful athlete then sacrifices must
        be made, it’s the nature of the beast. So stop making excuses of why you
        can’t get better today…. If it is important to you then you will find a
        way to make it happen. I’m not saying we should put our development
        above everything in our lives such as God, family or school. Just to be
        flexible with your leisure time and make training a priority. Successful
        athletes devote hours and hours to their craft. If you want to be
        successful then PUT IN WORK!
      </p>

      <h3>Weight Room: Bigger, Faster, Stronger</h3>

      <p>
        GET IN THE WEIGHT ROOM! Everyone wants an edge against their competition
        and the weight room is a perfect place to gain that (No Pun intended).
        With proper training the weight room can enhance the athlete&apos;s overall
        strength along with mobility and stability. Athletes need to focus on
        Sports Specific resistance training for optimal results. Sports specific
        training includes training in all planes of motion with emphasis on
        movement patterns that occur in their sport. Along with resistance
        training you also need rest and a healthy diet. Resistance training only
        breaks down the muscle fibers a healthy diet and adequate rest rebuilds
        the muscle fiber back stronger. The correct balance of resistance
        training, rest, and diet can take an athlete's performance to the next
        level and improve their chances of being successful.
      </p>

      <h3>Seek Development</h3>

      <p>
        Fall in LOVE with the GAME and the GRIND. Successful athletes view the
        “GRIND” in a positive connotation. The “GRIND” offers growth and
        development in all aspects physically, mentally, and spiritually.
        Getting better at your sport takes time and certainly doesn&apos;t happen
        overnight. There will be some days where it is easy and you think you
        have it figured out then the next day feels as if you are a beginner
        again. Don&apos;t get discouraged when it gets difficult just remember that
        your hard work will pay off. Become obsessed with the “GRIND”.
      </p>

      <h3>Conclusion</h3>

      <p>
        Remember not every athlete will achieve success in sports. Also not
        every athlete will put in the hard work, time and dedication for
        success. If you want to be successful then live the lifestyle of
        successful athletes. Success is not something that happens by accident
        and will not be handed to you. Waiting or wishing for success will not
        work. I encourage athletes to take initiative in your career and give
        yourself the best opportunity for success. Now go and find your own
        pieces of the puzzle for success!
      </p>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>